<template>
  <div class="student-list">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ 'dash.student.list.title' | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <filter-student @setFilterData="setFilterData" />
        </v-card>
         <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlertTable"
          >{{ alertMsgTable }}</v-alert>
          <form-tables ref="formTables" :filterData="filterData" @handleAlertTables="handleAlertTables()" />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import FilterStudent from '@/apps/dashboard/components/filters/filterStudent'
import FormTables from '../../components/tables/formTables'
  export default {
    name: 'FormList',
    components: {
      FilterStudent,
      FormTables,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        isShowAlertTable: false,
        alertMsgTable: '',
        filterData: {
          name: '',
          status: '',
          program_study: '',
          season: ''
        }
      }
    },
    created() {
      setTimeout(() => {
        this.$refs.formTables && this.$refs.formTables.getDataFromApi()
      }, 100)
    },
    methods: {
      setFilterData (params) {
        this.filterData.name = params.name
        this.filterData.status = params.status === 'Semua' ? '' : params.status
        this.filterData.program_study = params.program_study === 'Semua' ? '' : params.program_study
        this.filterData.season = params.season === 0 ? '' : params.season
        this.filterData.period = Date.parse(new Date(params.period))/1000 ? Date.parse(new Date(params.period))/1000 : 0
        this.$refs.formTables && this.$refs.formTables.setFilterData()
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      },
      handleAlertTables (isShowAlertTable, msg) {
        this.isShowAlertTable = isShowAlertTable
        this.alertMsgTable = msg
      },
    },
  }
</script>

<style lang="scss" scoped>
.student-list {

}
</style>